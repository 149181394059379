<template>
  <div>
    <v-dialog
      v-if="open"
      v-model="open"
      persistent
      fullscreen
      content-class="elevation-0"
    >
      <v-card id="retinaPopup" elevation="0">
        <div id="retinaPopup-wrapper">
          <v-card-title class="headline dialog-title">
            <span v-html="page.title"></span>
            <v-spacer></v-spacer>
            <v-btn dark text @click="close()">
              <span class="home-label">Back to Home</span>
              <img src="../assets/home.png" class="home" />
            </v-btn>
          </v-card-title>
          <v-card-text class="popup-text">
            <popup-content :pages="pages" :page="page"></popup-content>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import analytics from "../services/analytics";
import util from "@/services/utilService";
export default {
  name: "Popup",
  components: {
    PopupContent: () => import("@/components/PopupContent.vue")
  },
  props: ["pages"],
  data: () => ({
    open: false,
    page: {}
  }),
  methods: {
    close() {
      window.$app.checking = false;
      const id = "menu";
      analytics.page(id);
      this.$router.push({ name: id });
      this.open = false;
    },
    openPopup(id) {
      this.$hub.$emit("startScreenSaverCheck");
      this.page = util.getById(id, this.pages);
      if (this.page) {
        this.open = true;
      }
    }
  },
  mounted() {
    this.$hub.$on("openPopup", this.openPopup);
    this.$hub.$on("closePopup", this.close);
    const ID = this.$route.name;
    if (ID !== "menu") {
      setTimeout(() => {
        analytics.page(ID);
        this.$hub.$emit("openPopup", ID);
      }, 500);
    }
  },
  beforeDestroy() {
    this.$hub.$off("openPopup");
    this.$hub.$off("closePopup");
  }
};
</script>

<style lang="scss">
#retinaPopup {
  //max-width: calc(800px + 2rem) !important;
  width: 100vw;
  #retinaPopup-wrapper {
    margin: 40px 40px 0;
    width: calc(100vw - 80px) !important;
    height: calc(100vh - 160px);
    background-color: #e8e6e3;
    overflow-y: hidden;
  }
  .headline {
    background-color: #eb1600;
    color: white;
    span {
      font-family: "Johnson-Medium", Arial, Helvetica, sans-serif;
      word-break: keep-all;
      font-size: 30px;
    }
    button {
      padding: 0;
      min-width: 44px;
      .home {
        height: 24px;
        padding-left: 10px;
      }
      .home-label {
        font-size: 13px;
        text-transform: uppercase;
        font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
      }
    }
  }
  .popup-text {
    padding-top: 1rem;
    overflow-y: auto;
    max-height: calc(95vh - 183px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 767px) {
  .home-label {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #retinaPopup #retinaPopup-wrapper {
    margin: 0 !important;
    margin-top: 60px !important;
    width: 100vw !important;
    height: calc(100vh - 160px) !important;
    .headline span {
      font-size: 21px;
    }
  }
}
</style>
